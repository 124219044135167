// Version: 2.9.4
/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import '../colors';

.tns-outer {
  padding: 0 !important; // remove padding: clientWidth = width + padding (0) = width
  [hidden] {
    display: none !important;
  }
  [aria-controls],
  [data-action] {
    cursor: pointer;
  }
}
.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
  > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.tns-horizontal {
  &.tns-subpixel {
    white-space: nowrap;
    > .tns-item {
      display: inline-block;
      vertical-align: top;
      white-space: normal;
    }
  }
  &.tns-no-subpixel {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > .tns-item {
      float: left;
    }
  }
  &.tns-carousel {
    &.tns-no-subpixel {
      > .tns-item {
        margin-right: -100%;
      }
    }
  }
}
.tns-no-calc {
  position: relative;
  left: 0;
}
.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; // make sure slider container is visible
  // overflow: hidden;
  > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s;
  }
  > .tns-slide-active {
    position: relative;
    left: auto !important;
  }
  > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s;
  }
}
.tns-autowidth {
  display: inline-block;
}
.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
  &.tns-complete {
    opacity: 1;
  }
}
.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}
.tns-ovh {
  overflow: hidden;
  // added for hover
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  // end for hover
}
.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}
.tns-transparent {
  opacity: 0;
  visibility: hidden;
}
.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}
.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

// *** Fix a viewport issue in initialization
.tns-vpfix {
  white-space: nowrap;
  > div,
  > li {
    display: inline-block;
  }
}

// *** Detecting browser capability ***
$width: 310px;
$height: 10px;
$count: 70;
$perpage: 3;

.tns-t {
  &-subp2 {
    margin: 0 auto;
    width: $width;
    position: relative;
    height: $height;
    overflow: hidden;
  }
  &-ct {
    width: calc(100% * $count / $perpage);
    width: -webkit-calc(100% * #{$count} / #{$perpage});
    width: -moz-calc(100% * #{$count} / #{$perpage});
    width: calc(100% * #{$count} / #{$perpage});
    position: absolute;
    right: 0;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    > div {
      width: calc(100% / $count);
      width: -webkit-calc(100% / #{$count});
      width: -moz-calc(100% / #{$count});
      width: calc(100% / #{$count});
      height: $height;
      float: left;
    }
  }
}

.tns-nav {
  text-align: center;
  margin: 10px 25px;
  & > [aria-controls] {
    width: 9px;
    height: 9px;
    padding: 0;
    margin: 0 5px;
    border-radius: 50%;
    background: #ddd;
    border: 0;
  }
  & > .tns-nav-active {
    background: #999;
  }
}

.tns-controls {
  text-align: center;
  // margin-top: 40px;
  & > [aria-controls] {
    color: $theme-primary;
    width: 45px;
    height: 45px;
    padding: 0;
    margin: 0 8px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid inherit;
    &:hover,
    &:active {
      color: #fff;
      background-color: $theme-primary;
    }
    &[disabled] {
      opacity: 0.35;
      cursor: not-allowed;
      &:hover {
        color: $theme-primary;
        background-color: transparent;
      }
    }
  }
  & > button {
    border: 1px solid $theme-primary;
  }
}

.case-studies {
  .tns-controls {
    text-align: center;
    // margin-top: 40px;
    & > [aria-controls] {
      color: $theme-secondary;
      width: 45px;
      height: 45px;
      padding: 0;
      margin: 0 8px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid inherit;
      &:hover,
      &:active {
        color: $theme-primary;
        background-color: $theme-secondary;
      }
      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
          color: $theme-secondary;
          background-color: transparent;
        }
      }
    }
    & > button {
      border: 1px solid $theme-secondary;
    }
  }
}

.pillars-slider-wrap {
  .tns-ovh {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50px;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(125, 185, 232, 0) 0%,
        rgba(214, 246, 149, 1) 98%,
        rgba(216, 247, 147, 1) 100%
      );
    }
  }
  .tns-nav {
    text-align: center;
    margin: 30px 0 10px 0;
    & > [aria-controls] {
      width: 15px;
      height: 15px;
      padding: 0;
      margin: 0 5px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid $theme-primary;
    }
    & > .tns-nav-active {
      background: $theme-primary;
    }
  }
}

@media (min-width: 992px) {
  .pillars-slider-wrap {
    .tns-nav {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
      & > [aria-controls] {
        display: block;
        margin: 8px 0;
      }
    }
  }
}
