/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: #2b6088 !important;
}

.text-theme-primary-alt {
  color: #2e4b7c !important;
}

.text-theme-secondary {
  color: #bf1c22 !important;
}

.text-theme-red {
  color: #be2327 !important;
}

.text-theme-danger {
  color: #fb4f16 !important;
}

.border-theme-secondary {
  border-color: #bf1c22 !important;
}

.text-yellow {
  color: #ff9900;
}

.text-green {
  color: #339966;
}

.text-orange {
  color: #f87e17 !important;
}

.text-teal {
  color: #48b8b8 !important;
}

.text-icon-blue {
  color: #2b6088;
}

.text-icon-green {
  color: #21d353;
}

.text-icon-pink {
  color: #ad1a74;
}

.text-icon-yellow {
  color: #cfc439;
}

/* ----------------------------------------------------------------------------
    Backgrounds
----------------------------------------------------------------------------- */
.bg-theme-primary {
  background-color: #2b6088 !important;
}

.bg-theme-primary-alt {
  background-color: #2e4b7c !important;
}

.bg-theme-secondary {
  background-color: #bf1c22 !important;
}

.bg-theme-light {
  background-color: #f0f0f0 !important;
}

.bg-theme-callout {
  background-color: #bae7f5 !important;
}

.bg-theme-highlight {
  background-color: #34b9f6 !important;
}

.bg-theme-green {
  background-color: #468148 !important;
}

.bg-theme-orange {
  background-color: #f87e17 !important;
}

.bg-theme-teal {
  background-color: #48b8b8 !important;
}

.bg-gr-blue {
  background: #0b406c;
  background: linear-gradient(180deg, #0b406c 0%, #1280bf 100%);
}

.bg-gr-blue-med {
  background: #254d80;
  background: linear-gradient(320deg, #192d47 0%, #254d80 100%);
}

.bg-gr-blue-dark {
  background: #110022;
  background: linear-gradient(180deg, #065492 6%, #110022 89%);
}

.bg-gr-green {
  background: #3d6820;
  background: linear-gradient(180deg, #3d6820 0%, #70ad46 100%);
}

.bg-gr-grey {
  background: #555354;
  background: linear-gradient(180deg, #555354 0%, #a3a3a3 100%);
}

.bg-gr-orange {
  background: #bd4f04;
  background: linear-gradient(180deg, #bd4f04 0%, #ec7c30 100%);
}

.bg-gr-yellow {
  background: #bd8e02;
  background: linear-gradient(180deg, #bd8e02 0%, #febf00 100%);
}

/* Learning Hubs */
.bg-theme-central {
  background-color: #22053d !important;
}

.bg-theme-cps {
  background-color: #b777a3 !important;
}

/* ----------------------------------------------------------------------------
    Borders
----------------------------------------------------------------------------- */
.border-blue {
  border-color: #1280bf !important;
}

.border-green {
  border-color: #3d6820 !important;
}

.border-grey {
  border-color: #555354 !important;
}

.border-orange {
  border-color: #bd4f04 !important;
}

.border-secondary {
  border-color: #bf1c22 !important;
}

.border-yellow {
  border-color: #febf00 !important;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
  cursor: grab;
}
.tns-ovh:active {
  cursor: grabbing;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.tns-nav {
  text-align: center;
  margin: 10px 25px;
}
.tns-nav > [aria-controls] {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
}
.tns-nav > .tns-nav-active {
  background: #999;
}

.tns-controls {
  text-align: center;
}
.tns-controls > [aria-controls] {
  color: #2b6088;
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0 8px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid inherit;
}
.tns-controls > [aria-controls]:hover, .tns-controls > [aria-controls]:active {
  color: #fff;
  background-color: #2b6088;
}
.tns-controls > [aria-controls][disabled] {
  opacity: 0.35;
  cursor: not-allowed;
}
.tns-controls > [aria-controls][disabled]:hover {
  color: #2b6088;
  background-color: transparent;
}
.tns-controls > button {
  border: 1px solid #2b6088;
}

.case-studies .tns-controls {
  text-align: center;
}
.case-studies .tns-controls > [aria-controls] {
  color: #bf1c22;
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0 8px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid inherit;
}
.case-studies .tns-controls > [aria-controls]:hover, .case-studies .tns-controls > [aria-controls]:active {
  color: #2b6088;
  background-color: #bf1c22;
}
.case-studies .tns-controls > [aria-controls][disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.case-studies .tns-controls > [aria-controls][disabled]:hover {
  color: #bf1c22;
  background-color: transparent;
}
.case-studies .tns-controls > button {
  border: 1px solid #bf1c22;
}

.pillars-slider-wrap .tns-ovh {
  position: relative;
}
.pillars-slider-wrap .tns-ovh::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, rgb(214, 246, 149) 98%, rgb(216, 247, 147) 100%);
}
.pillars-slider-wrap .tns-nav {
  text-align: center;
  margin: 30px 0 10px 0;
}
.pillars-slider-wrap .tns-nav > [aria-controls] {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #2b6088;
}
.pillars-slider-wrap .tns-nav > .tns-nav-active {
  background: #2b6088;
}

@media (min-width: 992px) {
  .pillars-slider-wrap .tns-nav {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
  }
  .pillars-slider-wrap .tns-nav > [aria-controls] {
    display: block;
    margin: 8px 0;
  }
}